import React from "react";
import Switch from '@material-ui/core/Switch';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import '../../scss/tables/_table.scss'
import moment from "moment";

class StepOne extends React.Component {
  is_mounted = false;

  state = {
    selectedDate: moment().add(15, 'minutes').toDate(),
    table_rows: 
    [
      {
        jour: 'lundi',
        horaires: [
          { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
          //{ ouverture: moment().add(3, 'hour').toDate(), fermeture: moment().add(4, 'hour').toDate() }
        ],
        isOpen: true
      },
      {
        jour: 'mardi',
        horaires: [
          { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
        ],
        isOpen: true
      },
      {
        jour: 'mercredi',
        horaires: [
          { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
        ],
        isOpen: true
      },
      {
        jour: 'jeudi',
        horaires: [
          { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
        ],
        isOpen: true
      },
      {
        jour: 'vendredi',
        horaires: [
          { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
        ],
        isOpen: true
      },
      {
        jour: 'samedi',
        horaires: [
          { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
        ],
        isOpen: true
      },
      {
        jour: 'dimanche',
        horaires: [
          { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
        ],
        isOpen: true
      }
    ],
    columns: [
      { label: 'Jour', field: 'jour', sort: 'asc', },
      { label: 'Ouvert ce jour', field: 'closed', sort: 'asc', },
      { label: "Heure d'ouverture", field: 'ouverture', sort: 'asc', },
      { label: 'Heure de fermeture', field: 'fermeture', sort: 'asc', }
    ],
  }

  Change_Hour = (date, dayIndex, index, momentOfDay) => {
    this.state.table_rows[dayIndex]['horaires'][index][momentOfDay] = date.toDate()
    this.setState({table_rows: this.state.table_rows})
  }

  Change_Open = (value, dayIndex) => {
    this.state.table_rows[dayIndex].isOpen = !this.state.table_rows[dayIndex].isOpen
    this.setState({table_rows: this.state.table_rows})
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <section>
        <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="text-label">Nom du commerce*</label>
              <input
                type="text"
                name="place"
                className="form-control"
                placeholder='Nom de votre commerce'
                onChange={(e) => this.props.onChange('shopName', e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="text-label">Adresse du commerce*</label>
              <input
                type="text"
                name="place"
                className="form-control"
                placeholder='Adresse de votre commerce'
                onChange={(e) => this.props.onChange('shopAddress', e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-12 mb-3">
            <div className="table-responsive">
              <div className="display mb-4 dataTablesCard customer-list-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{this.state.columns[0].label}</th>
                      <th scope="col">{this.state.columns[1].label}</th>
                      <th scope="col">{this.state.columns[2].label}</th>
                      <th scope="col">{this.state.columns[3].label}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.table_rows.map((table_item, table_index) => (
                      table_item.horaires.map((item, index) => (
                        <tr>
                          <td scope="row" style={{fontWeight: 600}}>{index == 0 ? table_item.jour : undefined}</td>
                          <td>{index == 0 ? <Switch checked={table_item.isOpen} onChange={(value) => this.Change_Open(value, table_index)} inputProps={{ 'aria-label': 'secondary checkbox' }} /> : undefined}</td>
                          <td><TimePicker ampm={false} value={item.ouverture} onChange={(date) => this.Change_Hour(date, table_index, index, 'ouverture')} /></td>
                          <td><TimePicker ampm={false} value={item.fermeture} onChange={(date) => this.Change_Hour(date, table_index, index, 'fermeture')} /></td>
                        </tr>
                      ))
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      </MuiPickersUtilsProvider>
    )
  }
}

export default StepOne;
