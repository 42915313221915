import React, { Fragment } from "react";

import Multistep from "react-multistep";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

import Switch from '@material-ui/core/Switch';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import '../../scss/tables/_table.scss'
import moment from "moment";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepTwoClass from "./StepTwoClass";
import StepThree from "./StepThree";
import StepFour from "./StepFour";

import '../../css/bootstrap-select.min.css'
import '../../App.css'

const createFormData = (photo, body = {}) => {
  const data = new FormData();

  data.append('photo', photo);

  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
}

class Wizard extends React.Component {
  state = {
    id_place: 1,
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    email: '',
    phone: '',
    shopName: '',
    shopAddress: '',
    icon_image_file: undefined,
    icon_image_file_meta: undefined,
    cover_image_file: undefined,
    cover_image_file_meta: undefined,
    appearance: 'clair',
    primary_color: '#2fb5dd',
    isModalOpen: false,
    table_rows:
      [
        {
          jour: 'lundi',
          horaires: [
            { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
            //{ ouverture: moment().add(3, 'hour').toDate(), fermeture: moment().add(4, 'hour').toDate() }
          ],
          isOpen: true
        },
        {
          jour: 'mardi',
          horaires: [
            { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
          ],
          isOpen: true
        },
        {
          jour: 'mercredi',
          horaires: [
            { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
          ],
          isOpen: true
        },
        {
          jour: 'jeudi',
          horaires: [
            { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
          ],
          isOpen: true
        },
        {
          jour: 'vendredi',
          horaires: [
            { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
          ],
          isOpen: true
        },
        {
          jour: 'samedi',
          horaires: [
            { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
          ],
          isOpen: true
        },
        {
          jour: 'dimanche',
          horaires: [
            { ouverture: moment('11:00', 'HH:mm').toDate(), fermeture: moment('22:00', 'HH:mm').add(1, 'hour').toDate() },
          ],
          isOpen: true
        }
      ],
    columns: [
      { label: 'Jour', field: 'jour', sort: 'asc', },
      { label: 'Ouvert ce jour', field: 'closed', sort: 'asc', },
      { label: "Heure d'ouverture", field: 'ouverture', sort: 'asc', },
      { label: 'Heure de fermeture', field: 'fermeture', sort: 'asc', }
    ],
  }

  onChange = (variable, value) => {
    this.setState({ [variable]: value })
  }

  Change_Hour = (date, dayIndex, index, momentOfDay) => {
    this.state.table_rows[dayIndex]['horaires'][index][momentOfDay] = date.toDate()
    this.setState({ table_rows: this.state.table_rows })
  }

  Change_Open = (value, dayIndex) => {
    this.state.table_rows[dayIndex].isOpen = !this.state.table_rows[dayIndex].isOpen
    this.setState({ table_rows: this.state.table_rows })
  }

  Submit = async () => {
    let fet = await fetch('https://yassbk.com/food/create_place', {
      method: "POST",
      body: JSON.stringify({
        all_state: this.state
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    if (rep && this.state.icon_image_file) {
      fetch(`https://yassbk.com/food/upload_app_image`, {
        method: 'POST',
        body: createFormData(this.state.icon_image_file, {
          last_id: rep.last_id,
          name: 'icon'
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('response', response)
          if (!this.state.isModalOpen) this.setState({isModalOpen: true})
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    if (rep && this.state.cover_image_file) {
      fetch(`https://yassbk.com/food/upload_cover_image`, {
        method: 'POST',
        body: createFormData(this.state.cover_image_file, {
          last_id: rep.last_id,
          name: 'cover'
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('response', response)
          if (!this.state.isModalOpen) this.setState({isModalOpen: true})
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    if (rep) {
      if (!this.state.isModalOpen) this.setState({isModalOpen: true})
    } else {
      alert('Une erreur c\'est produite.')
    }
    // if (rep.success) {
    //   await fetch('http://192.168.1.26:5000/generate', {
    //     method: "POST",
    //     body: JSON.stringify({
    //       all_state: this.state,
    //       last_id: rep.last_id
    //     }),
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   })
    // }
  }

  StepTowInside = (props) => (
    <section>
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="form-group">
            <label className="text-label">Nom du commerce*</label>
            <input
              type="text"
              name="place"
              className="form-control"
              placeholder='Nom de votre commerce'
              value={this.state.shopName}
              onChange={(e) => this.onChange('shopName', e.target.value)}
              required
            />
          </div>
        </div>
        <div className="col-lg-12 mb-3">
          <div className="form-group">
            <label className="text-label">Adresse du commerce*</label>
            <input
              type="text"
              name="place"
              className="form-control"
              placeholder='Adresse de votre commerce'
              value={this.state.shopAddress}
              onChange={(e) => props.onChange('shopAddress', e.target.value)}
              required
            />
          </div>
        </div>
        <div className="col-lg-12 mb-3">
          <div className="table-responsive">
            <div className="display mb-4 dataTablesCard customer-list-table">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{this.state.columns[0].label}</th>
                    <th scope="col">{this.state.columns[1].label}</th>
                    <th scope="col">{this.state.columns[2].label}</th>
                    <th scope="col">{this.state.columns[3].label}</th>
                  </tr>
                </thead>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <tbody>
                    {
                      this.state.table_rows.map((table_item, table_index) => (
                        table_item.horaires.map((item, index) => (
                          <tr>
                            <td scope="row" style={{ fontWeight: 600 }}>{index == 0 ? table_item.jour : undefined}</td>
                            <td>{index == 0 ? <Switch checked={table_item.isOpen} onChange={(value) => this.Change_Open(value, table_index)} color='primary' inputProps={{ 'aria-label': 'secondary checkbox' }} /> : undefined}</td>
                            <td><TimePicker ampm={false} value={item.ouverture} onChange={(date) => this.Change_Hour(date, table_index, index, 'ouverture')} /></td>
                            <td><TimePicker ampm={false} value={item.fermeture} onChange={(date) => this.Change_Hour(date, table_index, index, 'fermeture')} /></td>
                          </tr>
                        ))
                      ))
                    }
                  </tbody>
                </MuiPickersUtilsProvider>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )

  render() {

    const StepFourInside = () => (
      <div className='row d-flex justify-content-center'>
        <h2 style={{ textAlign: 'center' }}>Confirmer la création de l'application</h2>
        <p></p>
        <p style={{ textAlign: 'center' }}>Un email contenant un lien de téléchargement de l'application vous sera envoyé, à l'adresse email renseignée plus tôt, sous 24 heures.</p>
        <p style={{ textAlign: 'center' }}>Veuillez noter que l'application sera délivrée en .apk et sera seulement installable sur smartphone android.</p>
        <p style={{ textAlign: 'center' }}>Notez également que le projet est en cours de développement il se peut donc que vous rencontriez certains bugs.</p>
        <div className='col-md-4 d-flex justify-content-center' style={{ marginTop: 10 }}>
          <button className='btn btn-lg' onClick={() => this.Submit()} style={{ backgroundColor: '#2fb5dd', color: '#fff', fontWeight: 500 }}>Créer mon application</button>
        </div>
      </div>
    )

    const steps = [
      { name: "Personal Info", component: <StepOne onChange={this.onChange} current={this.state} /> },
      { name: "Company Info", component: <this.StepTowInside onChange={this.onChange} /> },
      { name: "Business Hours", component: <StepThree onChange={this.onChange} current={this.state} /> },
      { name: "Email Setup", component: <StepFourInside /> },
    ]

    return (
      <Fragment>
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Prototype app builder</h4>
              </div>
              <div className="card-body">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  id="step-form-horizontal"
                  className="step-form-horizontal"
                >
                  <Multistep
                    showNavigation={false}
                    steps={steps}
                    prevStyle={prevStyle}
                    nextStyle={nextStyle}
                  />
                </form>
              </div>
            </div>
          </div>
          <Modal className="fade" show={this.state.isModalOpen}>
            <Modal.Header>
              <Modal.Title>Enregistrement effectué avec succès</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => this.setState({isModalOpen: false})}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              {/* <p>Votre application personnalisée est en train d'être générée.</p>
              <p>Vous recevrez par email un lien de téléchargement sous peu.</p>
              <p>En attendant vous pouvez accéder à votre panneau de gestion.</p> */}
              <p>Voici vos identifiants de connexion à votre <a style={{color: '#2fb5dd'}} href='https://dashboard.yassbk.com/'>panneau de gestion</a>.</p>
              <p>Email: <span style={{color: '#2fb5dd'}}>{this.state.email}</span></p>
              <p>Mot de passe: <span style={{color: '#2fb5dd'}}>test</span></p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => this.setState({isModalOpen: false})}
                style={{backgroundColor: '#e6f6fd', color: '#2fb5dd', borderColor: '#2fb5dd'}}
              >
                Fermer
              </Button>
              <Button style={{backgroundColor: '#2fb5dd', borderColor: '#2fb5dd'}} onClick={() => window.open('https://dashboard.yassbk.com/')}>Ouvrir votre panneau de gestion</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Fragment>
    )
  }
};
const prevStyle = {
  background: "#F7FAFC",
  borderWidth: "0px",
  color: "#333333",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "600",
  padding: "0.55em 2em",
  border: "1px solid #EEEEEE",
  marginRight: "1rem",
};
const nextStyle = {
  background: "#2fb5dd",
  borderWidth: "0px",
  color: "#fff",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "600",
  padding: "0.55em 2em",
};

export default Wizard;
