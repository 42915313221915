import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './components/Home'
import Wizard from './components/Wizard/Wizard'

import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/_form-steps.scss'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/wiz">
          <Wizard />
        </Route>
        <Route path="/">
          <Wizard />
        </Route>
      </Switch>
    </Router>
  );
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}