import React from "react";
import "react-dropzone-uploader/dist/styles.css"
import Drop from "react-dropzone-uploader"
import { SketchPicker } from 'react-color';
import '../../index.css'
import point_b from '../../images/point-b.jpeg'
import menu from '../../images/menu.jpeg'
import burger from '../../images/burger.jpg'

const handleSubmit = (files) => {
  console.log(files.map((f) => f.meta))
  let p_class = document.querySelectorAll(`.dzu-previewContainer`)
  if (p_class.length > 1) {
    for (let i = 0; i < p_class.length; i++) {
      if (i != (p_class.length - 1)) p_class[i].style.display = 'none'
    }
  }
}

const createFormData = (photo, body = {}) => {
  const data = new FormData();

  data.append('photo', photo);

  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
}

class StepThree extends React.Component {
  state = {
    icon_image_file: this.props.current.icon_image_file,
    icon_image_file_meta: this.props.current.icon_image_file_meta,
    cover_image_file: this.props.current.cover_image_file,
    cover_image_file_meta: this.props.current.cover_image_file_meta,
    appearance: this.props.current.appearance,
    primary_color: this.props.current.primary_color
  }

  handleChangeStatus = ({ meta, file }, status) => {
    console.log('handleChangeStatus => ', status, meta, file)
    this.setState({ icon_image_file: file })
    if (status == 'done') {
      this.props.onChange('icon_image_file', file)
      // fetch(`https://yassbk.com/food/upload_app_image`, {
      //   method: 'POST',
      //   body: createFormData(file, {
      //     last_id: 16,
      //     name: 'icon'
      //   }),
      // })
      //   .then((response) => response.json())
      //   .then((response) => {
      //     console.log('response', response)
      //   })
      //   .catch((error) => {
      //     console.log('error', error)
      // })
    }
  }

  handleChangeStatusCover = ({ meta, file }, status) => {
    console.log('handleChangeStatus => ', status, meta, file)
    this.setState({ cover_image_file: file , cover_image_file_meta: meta })
    if (status == 'done') {
      this.props.onChange('cover_image_file', file)
      this.props.onChange('cover_image_file_meta', meta)
    }
  }

  Change_Appearance = (value) => {
    this.setState({ appearance: value })
    this.props.onChange('appearance', value)
  }

  Change_Primary_Color = (color) => {
    this.setState({ primary_color: color.hex })
    this.props.onChange('primary_color', color.hex)
  }

  render() {
    return (
      <section>
        <div className="row">
          <div className="col-lg-3 mb-2" style={{ paddingLeft: 20 }}>
            <div className="form-group col-md-12">
              <label className="text-label">Image de couverture</label>
              <Drop
                // getUploadParams={getUploadParams}
                onChangeStatus={this.handleChangeStatusCover}
                onSubmit={handleSubmit}
                multiple={false}
                PreviewComponent={(p) => {
                  console.log(p)
                  //p.files = p.files.length > 1 ?  p.files.slice(1, 2) : p.files
                  let p_class = document.querySelectorAll(`.${p.className}`)
                  if (p_class.length > 1) {
                    for (let i = 0; i < p_class.length; i++) {
                      if (i != (p_class.length - 1)) p_class[i].style.display = 'none'
                    }
                  }
                  return (
                    <div className={p.className}>
                      <img className={p.imageClassName} src={p.files[p.files.length - 1].meta.previewUrl}></img>
                    </div>
                  )
                }}
                inputContent="Faites glisser une image ici"
                //InputComponent={() => <p></p>}
                inputWithFilesContent="Modifier l'image"
                SubmitButtonComponent={() => <p></p>}
                submitButtonDisabled={true}
                accept="image/*"
                styles={{
                  dropzone: {
                    minHeight: 150,
                    maxHeight: 250,
                    width: 150,
                    backgroundColor: "#f2f4fa",
                    border: "1px dashed #DDDFE1",
                    overflow: "hidden",
                    margin: 15
                  },
                  inputLabel: {
                    color: "#7e7e7e",
                    fontSize: "18px",
                    fontWeight: "normal",
                    backgroundColor: "#f2f4fa",
                  },
                }}
              />
            </div>
            <div className='form-group col-md-12' style={{ marginBottom: 15 }}>
              <label className="text-label" style={{ marginBottom: 15 }}>Apparence par défaut</label>
              <div style={{ display: 'flex' }}>
                <div className='appearence_choose' onClick={() => this.Change_Appearance('clair')}>
                  <label className="text-label">Clair</label>
                  <div className={`round ${this.state.appearance == 'clair' ? 'active' : undefined}`}></div>
                </div>
                <div className='appearence_choose' onClick={() => this.Change_Appearance('sombre')}>
                  <label className="text-label">Sombre</label>
                  <div className={`round ${this.state.appearance == 'sombre' ? 'active' : undefined}`} style={{ backgroundColor: '#121212' }}></div>
                </div>
              </div>
            </div>
            <div className='form-group col-md-12'>
              <label className="text-label">Couleur principale</label>
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                <SketchPicker color={this.state.primary_color} onChange={this.Change_Primary_Color} disableAlpha={true} />
              </div>
            </div>
            <div className="form-group col-md-12">
              <label className="text-label">Icone de l'application</label>
              <Drop
                // getUploadParams={getUploadParams}
                onChangeStatus={this.handleChangeStatus}
                onSubmit={handleSubmit}
                multiple={false}
                PreviewComponent={(p) => {
                  console.log(p)
                  //p.files = p.files.length > 1 ?  p.files.slice(1, 2) : p.files
                  let p_class = document.querySelectorAll(`.${p.className}`)
                  if (p_class.length > 1) {
                    for (let i = 0; i < p_class.length; i++) {
                      if (i != (p_class.length - 1)) p_class[i].style.display = 'none'
                    }
                  }
                  return (
                    <div className={p.className}>
                      <img className={p.imageClassName} src={p.files[p.files.length - 1].meta.previewUrl}></img>
                    </div>
                  )
                }}
                inputContent="Faites glisser une image ici"
                //InputComponent={() => <p></p>}
                inputWithFilesContent="Modifier l'image"
                SubmitButtonComponent={() => <p></p>}
                submitButtonDisabled={true}
                accept="image/png"
                styles={{
                  dropzone: {
                    minHeight: 150,
                    maxHeight: 250,
                    width: 150,
                    backgroundColor: "#f2f4fa",
                    border: "1px dashed #DDDFE1",
                    overflow: "hidden",
                    margin: 15
                  },
                  inputLabel: {
                    color: "#7e7e7e",
                    fontSize: "18px",
                    fontWeight: "normal",
                    backgroundColor: "#f2f4fa",
                  },
                }}
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2 d-flex justify-content-center">
            <div id='phone_container'
              style={
                this.state.appearance == 'clair'
                  ?
                  { backgroundColor: '#f2f2f2' }
                  :
                  { backgroundColor: '#121212' }
              }
            >
              <div className='status_bar' style={{ backgroundColor: this.state.appearance == 'clair' ? '#f2f2f2' : '#282828' }}></div>
              <div className='home_scrollable_container'>
                <div className='home_heading_image' style={this.state.cover_image_file_meta ? { backgroundImage: `url(${this.state.cover_image_file_meta.previewUrl})` } : undefined}>
                  {/* <img src={point_b} /> */}
                </div>
                <div className='home_heading_infos' style={{ backgroundColor: this.state.appearance == 'clair' ? '#fff' : '#282828' }}>
                  <span id='title' style={{ color: this.state.appearance == 'clair' ? '#000' : '#e5e5e7' }}>{this.props.current.shopName != '' ? this.props.current.shopName : 'Nom de votre restaurant'}</span>
                  <span id='address' style={{ color: this.state.appearance == 'clair' ? '#000' : '#ccc' }}>{this.props.current.shopAddress != '' ? this.props.current.shopAddress : '123 Boulevard Haussmann, Paris, France'}</span>
                  <span id='schedule' style={{ color: this.state.appearance == 'clair' ? '#000' : '#ccc' }}>Horaires d'ouverture <span style={{ marginLeft: 14 }}>11:30 - 22:00</span></span>
                </div>
                <div className='home_section' style={{ marginTop: 50 }}>
                  <label className='home_section_title' style={{ color: this.state.appearance == 'clair' ? '#000' : '#e5e5e7' }}>Notre sélection pour vous</label>
                  <div className='home_section_case' style={{ backgroundColor: this.state.appearance == 'clair' ? '#fff' : '#282828' }}>
                    <img src={burger} />
                    <div className='d-flex flex-column justify-content-between' style={{ height: '100%' }}>
                      <span id='title' style={{ color: this.state.appearance == 'clair' ? '#000' : '#e5e5e7' }}>Menu Burger Simple</span>
                      <span id='description' style={{ color: this.state.appearance == 'clair' ? '#000' : '#ccc' }}>1 Burger, servi avec frites et boisson 33 cl</span>
                      <span id='price' style={{ color: this.state.primary_color }}>9.70 €</span>
                    </div>
                  </div>
                  <div className='home_section_case' style={{ backgroundColor: this.state.appearance == 'clair' ? '#fff' : '#282828' }}>
                    <img src={burger} />
                    <div className='d-flex flex-column justify-content-between' style={{ height: '100%' }}>
                      <span id='title' style={{ color: this.state.appearance == 'clair' ? '#000' : '#e5e5e7' }}>Menu Burger Double</span>
                      <span id='description' style={{ color: this.state.appearance == 'clair' ? '#000' : '#ccc' }}>2 Burger, servi avec frites et boisson 33 cl</span>
                      <span id='price' style={{ color: this.state.primary_color }}>13.70 €</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='add_to_basket_container' style={{ backgroundColor: this.state.appearance == 'clair' ? '#fff' : '#282828' }}>
                <div id='button' style={{ backgroundColor: this.state.primary_color }}>
                  <span>Voir mon panier <span id='items'>1 produit</span></span>
                  <span>13.70 €</span>
                </div>
              </div>
              <div className='bottom_tab' style={{ backgroundColor: this.state.appearance == 'clair' ? '#fff' : '#282828' }}>
                <div className='bottom_tab_case'>
                  <svg className='active' style={{ fill: this.state.primary_color }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z" /></svg>
                  <label className='active' style={{ color: this.state.primary_color }}>Accueil</label>
                </div>
                <div className='bottom_tab_case'>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="history" class="svg-inline--fa fa-history fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"></path></svg>
                  <label>Commandes</label>
                </div>
                <div className='bottom_tab_case'>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                  <label>Mon compte</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default StepThree;
