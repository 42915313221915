import React from "react";

class StepOne extends React.Component {
  componentDidMount() {
    console.log(this.props)
  }

  render() {
    return (
      <section>
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className="form-group">
              <label className="text-label">Prénom*</label>
              <input
                type="text"
                name="Prénom"
                className="form-control"
                placeholder="Votre prénom"
                value={this.props.current.firstName}
                onChange={(e) => this.props.onChange('firstName', e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group">
              <label className="text-label">Nom*</label>
              <input
                type="text"
                name="Nom"
                className="form-control"
                placeholder="Votre nom"
                value={this.props.current.lastName}
                onChange={(e) => this.props.onChange('lastName', e.target.value)}
                required
              />
            </div>
          </div>
          {/* <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="text-label">Adresse*</label>
              <input
                type="text"
                name="place"
                className="form-control"
                placeholder='Votre adresse'
                onChange={(e) => this.props.onChange('address', e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group">
              <label className="text-label">Ville*</label>
              <input
                type="text"
                name="Nom"
                className="form-control"
                placeholder="Ville"
                onChange={(e) => this.props.onChange('city', e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group">
              <label className="text-label">Code postal*</label>
              <input
                type="text"
                name="Nom"
                className="form-control"
                placeholder="75000"
                onChange={(e) => this.props.onChange('zip', e.target.value)}
                required
              />
            </div>
          </div> */}
          <div className="col-lg-12 mb-2">
            <div className="form-group">
              <label className="text-label">Adresse email*</label>
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="exemple@mail.com"
                value={this.props.current.email}
                onChange={(e) => this.props.onChange('email', e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-12 mb-2">
            <div className="form-group">
              <label className="text-label">Numéro de téléphone*</label>
              <input
                type="number"
                name="phoneNumber"
                className="form-control"
                placeholder="0612345678"
                value={this.props.current.phone}
                onChange={(e) => this.props.onChange('phone', e.target.value)}
                required
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default StepOne;
